import React from 'react';
import PropTypes from 'prop-types';

import Layout from 'components/Layout';
import SEO from 'components/Seo';

const PrivacyPage = ({ location }) => (
  <Layout>
    <SEO
      keywords={['kopirun', 'delivery', 'food delivery', 'crowdsource', 'privacy policy']}
      path={location.pathname}
      title="Privacy Policy"
    />
    <div className="max-w-7xl mx-auto">
      <section className="flex-1 flex-col justify-center items-center mt-10 md:flex-row px-4 sm:px-6 lg:px-8">
        <h1 className="font-bold text-2xl no-underline mb-4">Privacy Policy</h1>
        <p className="mb-4">
          This Privacy Policy supplements our Terms of Service and explains how KopiRun, its
          subsidiaries and affiliates that link to this Privacy Policy, handle information collected
          and received in the course of your use of our websites, mobile application and services
          (collectively, the &ldquo;Services&rdquo;). It also governs the collection, use and
          disclosure of your information.
        </p>

        <p className="mb-4">
          &ldquo;Personal Data&rdquo; is any information which can be used to identify you or from
          which you are identifiable. This includes but is not limited to your name, nationality,
          telephone number, address, bank and credit card details, personal interests, email
          address, your image, government-issued identification numbers, biometric data, race, date
          of birth, marital status, religion, health information, vehicle and insurance information.
        </p>

        <h1 className="font-bold text-lg mb-4">1. Information we collect and receive</h1>

        <p className="mb-4">
          We may collect and receive information from you in the following situations:
        </p>

        <ul className="list-inside list-disc mb-4">
          <li>When you register for an account with us (&ldquo;Account&rdquo;).</li>
          <li>
            When you choose to connect your Account with an external third-party service or
            application, such as Facebook.
          </li>
          <li>
            When you use the features and functions available on our Services, for example, by
            creating requests, interacting with other users through our chat feature, or transacting
            with other users.
          </li>
          <li>If you contact us, for example, with a query or to report a problem.</li>
          <li>When you register for a survey, campaign, contest and/or lucky draw with us.</li>
          <li>When you interact with us offline or through an external third party.</li>
        </ul>

        <p className="mb-4">
          You are under no obligation to provide the information to us. However, if you choose to
          withhold the information or to revoke permission for us to receive the information, we may
          be unable to provide certain aspects of our Services to you.
        </p>

        <p className="mb-4">
          By providing us with any information relating to a third party (e.g. information of your
          spouse, children, parents, and/or employees), you represent to us that you have validly
          obtained the consent of the third party to provide us with their information for the
          respective purposes.
        </p>

        <p className="mb-4">We may collect and receive the following information below:</p>

        <ul className="list-inside list-disc mb-4">
          <li>Account credentials such as username</li>
          <li>
            Profile information such as, name, gender, email address, profile photo, mobile number,
            date of birth.
          </li>
          <li>Location information such as meet-up location, or the user&rsquo;s address.</li>
          <li>
            Any other information disclosed by you in any of the above-mentioned methods and/or
            through the use our Services.
          </li>
          <li>
            Aggregate data collected through the use of our Services, and information about how you
            interacted with our Apps, Website or services (such as features used and content
            viewed);
          </li>
          <li>Any communications between you and another user of our Services.</li>
          <li>Feedback, ratings and compliments</li>
          <li>
            Personal data you enter in messages when you use our in-app communication features.
          </li>
          <li>
            device information (such as hardware model and serial number, IP address, file names and
            versions and advertising identifiers, or any information that may provide indication of
            device or app modification)
          </li>
        </ul>

        <h1 className="font-bold text-lg mb-4"> 2. Your interactions with others</h1>

        <p className="mb-4">
          Our Services offer features which allow users to connect and communicate in groups which
          are public or semi-public, and to share information with each other to complete a
          transaction. Please use common sense and exercise good judgement when sharing information
          in these groups or with others. Such information may remain available on our Services
          after your Account has been deactivated, terminated or is otherwise closed. You are
          responsible for the information you choose to share in these groups or with other users.
        </p>

        <h1 className="font-bold text-lg mb-4">
          3. Why we collect the information and how we use it
        </h1>

        <p className="mb-4">
          We may use the information collected and received from you for the following purposes:
        </p>

        <ul className="list-inside list-disc mb-4">
          <li>
            To verify your identity, update our records and generally maintain your Account with us.
          </li>
          <li>Provide our Services and customise the content shown to you.</li>
          <li>Develop, operate, improve, deliver and maintain our Services.</li>
          <li>
            To process or resolve any dispute arising from or in connection with any transactions,
            in accordance with our Terms of Service.
          </li>
          <li>To detect or monitor any frauds or other prohibited content or activities.</li>
          <li>Responding to your queries and resolving complaints.</li>
          <li>
            To facilitate, confirm and/or provide updates or notifications in relation to any deals.
          </li>
          <li>
            Carry out polls, surveys, analysis and research on how our Services are being used and
            how we can improve them.
          </li>
          <li>
            To update you on your listings and our Services (e.g. new requests, new offers, new
            chats). These updates will be sent by email and/or through push notifications.
          </li>
          <li>
            To send you marketing and/or promotional materials on the latest services of KopiRun.
            These updates will be sent by email and/or through push notifications.
          </li>
        </ul>

        <h1 className="font-bold text-lg mb-4">4. Who we share your information with</h1>

        <p className="mb-4">We may share information about you in the following ways:</p>

        <p className="mb-4">
          When you register for an Account, the information which you make available on your profile
          may be viewed by other users of our Services. Other users of our Services would also be
          able to view the content which you upload and share on our Services.
        </p>

        <p className="mb-4">
          We may share the information with third party service providers who assist us in
          performing certain aspects of our Services on our behalf, such as processing transactions,
          payment, fulfilling requests for information, receiving and sending communications,
          updating marketing lists, analysing data, providing support services or in other tasks.
          Such service providers will only have access to your personal information to the extent
          necessary to perform their functions.
        </p>

        <p className="mb-4">
          We may share the information with our related and associate companies and affiliates for
          the purposes of performing certain aspects of our Services and for the purposes as set out
          in this Privacy Policy.
        </p>

        <p className="mb-4">
          We may share the information with our potential partners, investors and other parties with
          a view to a potential business partnership, collaboration, joint venture or otherwise in
          furtherance of our business.
        </p>

        <p className="mb-4">
          We may share any information collected in accordance with this Privacy Policy with: (i)
          governmental and other regulatory authorities or the courts in any jurisdiction; (ii) any
          third party claimants or potential third party claimants; or (iii) your card issuing bank,
          financial institution and/or payment service provider, to process or resolve any
          chargeback, payment reversal and/or dispute arising from or in connection with a
          transaction using our Services in accordance with our Terms of Service.
        </p>

        <p className="mb-4">
          We may disclose your personal information if required to do so by law or if we believe
          that such action is necessary to prevent fraud or crime or to protect our Services or the
          rights, property or personal safety of any person.
        </p>

        <h1 className="font-bold text-lg mb-4">5. Protecting your information</h1>

        <p className="mb-4">
          The security of your information is important to us. We have security measures in place to
          protect against the loss, misuse and alteration of information under our control. We also
          follow generally accepted industry standards to protect the information transmitted to us
          over the Internet, both during transmission and once we receive it. However, no method of
          transmission over the Internet, or method of electronic storage, is 100% secure.
          Therefore, while we strive to use commercially acceptable means to protect your
          information, we cannot guarantee its absolute security.
        </p>

        <p className="mb-4">
          It is important that you protect against unauthorised access of your Account and
          information by choosing your password carefully, and keeping your password and Account
          secure (e.g. by signing out after using our Services).
        </p>

        <h1 className="font-bold text-lg mb-4">6. Cookies and tracking technologies</h1>

        <p className="mb-4">
          KopiRun, and third parties with whom we partner, may use cookies, web beacons, tags,
          scripts, local shared objects such as HTML5 and Flash (sometimes called &ldquo;flash
          cookies&rdquo;), advertising identifiers (including mobile identifiers such as
          Apple&rsquo;s IDFA or Google&rsquo;s Advertising ID) and similar technology
          (&ldquo;Cookies&rdquo;) in connection with your use of the Websites and Apps. Cookies may
          have unique identifiers, and reside, among other places, on your computer or mobile
          device, in emails we send to you, and on our web pages. Cookies may transmit Personal Data
          about you and your use of the Service, such as your browser type, search preferences, IP
          address, data relating to advertisements that have been displayed to you or that you have
          clicked on, and the date and time of your use. Cookies may be persistent or stored only
          during an individual session.
        </p>

        <p className="mb-4">
          Additionally, we may share non-personally identifiable Personal Data with third parties,
          such as location data, advertising identifiers, or a cryptographic hash of a common
          account identifier (such as an email address), to facilitate the display of targeted
          advertising on third party platforms.
        </p>

        <p className="mb-4">
          If you do not wish for your Personal Data to be collected via Cookies on the Websites, you
          may deactivate cookies by adjusting your internet browser settings to disable, block or
          deactivate cookies, by deleting your browsing history and clearing the cache from your
          internet browser.
        </p>

        <h1 className="font-bold text-lg mb-4">7. Changes to this Privacy Policy</h1>

        <p className="mb-4">
          KopiRun shall have the right to modify, update or amend the terms of this Policy at any
          time by placing the updated Policy on the Websites. By continuing to use the Apps,
          Websites or Services, purchase products from KopiRun or continuing to communicate or
          engage with KopiRun following the modifications, updates or amendments to this Policy, you
          signify your acceptance of such modifications, updates or amendments.
        </p>

        <h1 className="font-bold text-lg mb-4">8. Third party sites and resources</h1>

        <p className="mb-4">
          Our Services may, from time to time, contain links to external sites or resources which
          are operated by third parties. We have no control over the content and privacy practices
          of such sites or resources. You are advised to review the privacy policies of these sites
          and resources operated by third parties and understand how your information may be used by
          those third parties.
        </p>

        <h1 className="font-bold text-lg mb-4">9. Contact us</h1>

        <p className="mb-4">
          If you have any questions, complaints, concerns or comments on our Privacy Policy, we
          welcome you to contact us by sending an email to support@kopirun.com. Your indication at
          the subject header would assist us in attending to your email speedily by passing it on to
          the relevant staff in our organisation. For example, you could insert the subject header
          as &quot;Accessing Personal Data&quot;.
        </p>
      </section>
    </div>
  </Layout>
);

PrivacyPage.propTypes = {
  location: PropTypes.object.isRequired
};

export default PrivacyPage;
